<template>
  <div class="title_top">
    <back-nav-last-page :icon="'img/backb.png'" :textColor="'#0A2042'" :backColor="'#F7F9FE'" />

    <div class="display_flex justify-content_flex-justify margin-top padding-lr-sm">
        <div class="mynav display_flex flex-direction_column align-items_center" :style="{backgroundImage: 'url(img/myBg1.png)'}" @click="fetchData(userid,1)">
            <p>邀请人数</p>
            <span class="margin-top-xs">{{invite_sum}}</span>
        </div>
        <div class="mynav display_flex flex-direction_column align-items_center margin-lr-sm" :style="{backgroundImage: 'url(img/myBg2.png)'}" @click="fetchData(userid,2)">
            <p>有效人数</p>
            <span class="margin-top-xs">{{effective_num}}</span>
        </div>
        <div class="mynav display_flex flex-direction_column align-items_center" :style="{backgroundImage: 'url(img/myBg3.png)'}">
            <p>下级累计赠送</p>
            <div class="display_flex align-items_center margin-top-xs">
                <img src="img/zuan.png" alt="">
                <span class="margin-left-xs">{{s_send_sum}}</span>
            </div>
        </div>
    </div>

    <div class="margin-lr-sm margin-bottom-xl">

        <div class="last_item display_flex justify-content_flex-justify align-items_center margin-bottom-sm" v-for="(item,index) in listData" :key="index">
            <div class="display_flex align-items_center">
                <div class="my-order">
                    <img v-if="index<3" class="my-num" :src="'img/'+(index+1)+'.png'" alt="">
                    <span v-else>{{index+1}}</span>
                </div>
                <div class="margin-left">
                  <div class="display_flex align-items_center last_news">
                      <p>{{item.nickname}}</p>
                      <span class="margin-left-xs">ID:{{item.u_id}}</span>
                  </div>
                  <div class="last_time margin-top-sm">注册时间：{{item.create_time}}</div>
                </div>
            </div>
            <div class="last_gift" v-if="item.is_auth == 3">
                <img src="img/renz.png" alt="">
            </div>
        </div>

        <div class="noData margin-top-xl" v-if="noData">
          <img src="img/noData.png" alt="">
        </div>

    </div>

  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import {sonInviteDetail} from "@/api/api";
import BackNavLastPage from '@/components/backNavLastPage.vue';
export default {
  name: 'lastInvite',
  components: {
    BackNavLastPage
  },
  data() {
    return {
      userid:'',
      invite_sum:'',
      s_send_sum:'',
      listData:[],
      noData:false,
      effective_num:''
    };
  },
  created(){
    this.$route.meta.title = this.$route.query.nickname
  },
  mounted() {
    this.userid = this.$route.query.id
    this.invite_sum = this.$route.query.invite_sum
    this.s_send_sum = this.$route.query.s_send_sum
    this.effective_num = this.$route.query.effective_num
    this.fetchData(this.userid,1)
  },
  methods: {
    fetchData(id,num) {
      const Params = {
        user_id:id,
        type:num
      }
      sonInviteDetail(Params).then(response => {
        this.listData = response.data
        if(response.data.length == 0){
          this.noData = true
        }
      },
      error => {
        this.$toast.fail(error)
      }).catch((error) => {
        this.$toast.fail(error)
      })
    },

  },
  watch:{
    '$route':"fetchData"
  }
};
</script>